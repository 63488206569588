import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Typography from '@material-ui/core/Typography'
import { useHistory } from 'react-router-dom'
import { useAppState, useActions } from 'states'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogSlide() {
  const state = useAppState()
  const actions = useActions()
  const [open, setOpen] = React.useState(true);
  const history = useHistory()

  const handleClose = () => {
    actions.toggleZeroDialog()
    setOpen(false);
  };

  const handleContact = () => {
   actions.toggleZeroDialog()
   const msg = `Bonjour,\n\nma recherche sur "${data.terms}" ne donne rien.` +
          '\n\nPourtant ces produits sont vendus dans certaines boutiques.' +
          '\n\nPouvez vous referencer cette/ces boutique(s) ?\n\n'

    history.push('/contact', { msg: msg });
    setOpen(false);
  }

  return (
      <Dialog
        open={open}
        TransitionComponent={Transition}
        /* keepMounted */
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          <Typography variant="inherit">
            {"Pas de resultat pour votre recherche ?"}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <Typography variant="inherit" >Trois solutions :<br/><br/></Typography>
            <Typography variant="inherit">1. Renouvelez votre recherche avec moins de termes;<br/></Typography>
            <Typography variant="inherit">2. Créer une alerte;<br/></Typography>
            <Typography variant="inherit">3. Nous envoyer un message pour demander le référencement des boutiques qui vendent ce produit.<br/></Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            J'ai compris
          </Button>
          {/* <Button onClick={handleAlert} color="secondary"> */}
          {/*   Je cree une alerte */}
          {/* </Button> */}
          <Button onClick={handleContact} color="secondary">
            Je signale une boutique
          </Button>
        </DialogActions>
      </Dialog>
  );
}
