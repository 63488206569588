import React, { useCallback, useEffect, useState } from 'react'
import { DataGrid, GridToolbar, frFR } from '@mui/x-data-grid';
import { jsonHeaders, formatPrice, formatDate } from 'lib'
import Chip from '@material-ui/core/Chip';
import { TablePagination } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import { useAppState, useActions } from 'states'


function CustomTablePagination() {
  const [pageSize, setPageSize] = useState(10)

  return (
    <TablePagination
      onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
      rowsPerPageOptions={[10, 50, 100]}
      /* pageSize={pageSize} */
    />
  )
}

const ViewListItems = () => {
  const state = useAppState()
  const [items, setItems] = useState([])


  const columns = [
    {
      field: '',
      flex: 0.1,
      sortable: false,
      headerName: 'Actions',
      renderCell: (params) => (
        <>
          <Chip
            clickable={true}
            color="secondary"
            size="small"
            label="Visiter" onClick={ (e) => handleRowClick(params, e)} />
          <Tooltip
            title="Rechercher sur le titre exact"
            aria-label="Rechercher sur le titre exact">
            <Chip
              color="primary"
              size="small"
              label="Similaires"
              clickable={true}
              onClick={(e) => handleSimilar(params, e)}
              style={{marginLeft: '7px'}} />
          </Tooltip>
        </>
      )
    },
    {
      field: 'id',
      type: 'number',
      hide: true
    },
    {
      field: 'title',
      headerName: 'Nom',
      headerAlign: 'left',
      flex: 0.4,
      sortable: true,
    },
    {
      field: 'cat',
      headerName: 'Categ.',
      headerAlign: 'center',
      align: 'center',
      sortable: true,
      flex: 0.1,
      renderCell: (params) => {
        switch(params.value)  {
          case 'materiels':
            return <Chip variant="outlined" size="small" style={{backgroundColor: 'pink'}} label="Matériel" />
          case 'eliquides':
            return <Chip variant="outlined" size="small" style={{backgroundColor: '#6be76b'}}  label="Eliquide" className="item-eliquides"/>
          case 'autres':
            return <Chip variant="outlined" size="small" style={{color: 'white', backgroundColor: '#7484dc'}} label="Autre" className="item-autres"/>
        }
      },
    },
    {
      field: 'url',
      headerName: 'Url',
      headerAlign: 'center',
      hide: true
    },
    {
      field: 'price',
      headerName: 'Prix',
      headerAlign: 'center',
      align: 'center',
      sortable: true,
      type: 'number',
      flex: 0.1,
      valueFormatter: (params) => {
        return formatPrice(params.value)
      },
    },
    {
      field: 'updated_at',
      headerName: 'Mis à jour',
      headerAlign: 'right',
      align: 'right',
      sortable: true,
      type: 'date',
      flex: 0.1,
      valueFormatter: (params) => {
        return formatDate(params.value)
      },
    },
    {
      field: 'shop',
      headerName: 'Boutique',
      headerAlign: 'center',
      align: 'center',
      description: 'Boutique',
      sortable: true,
      flex: 0.2,
    },
  ];

  const handleSimilar = (params, e) => {
    e.preventDefault()

    const { title } = params.row

    const win = window.open(`/search?terms=${title}&similar=true`, "_blank");
    win.focus();
  }

  const handleRowClick = (params, e) => {
    e.preventDefault()
    const { shop, url, title } = params.row

    fetch(`/api/items/click`, {
      method: 'POST',
      body: JSON.stringify({
        query_id: data.query_id,
        shop: shop,
        url: url,
        title: title
      }),
      headers: jsonHeaders()
    }).then(response => response.json())
      .then(_result => null)

    window.open(params.row.url, '_blank')
  }

  const allItems = useCallback(() => {
      const { eliquides, materiels, autres } = data.results.data
      const all = materiels.concat(eliquides).concat(autres).sort((a, b) => {
        const p1 =  parseFloat(a.attributes.price)
        const p2 = parseFloat(b.attributes.price)
        if(p1 < p2) return -1
        if(p1 > p2) return 1
        return 0
      })
      return all
  }, [data.results.data])

  useEffect(() => {
    const rows = allItems().map(({id, attributes}) => {
      const {url, title, cat, price, updated_at, shop} = attributes
      return { id, title, cat, price, updated_at, shop, url }
    })

    setItems(() => rows)
  }, [data.results.data]);

  return (
    <div style={{ height: 700, width: '100%' }} >
      <DataGrid
        localeText={frFR.props.MuiDataGrid.localeText}
        rows={items}
        /* onRowClick={handleRowClick} */
        columns={columns}
        pagination
        components={{
          Toolbar: GridToolbar,
        }}
        rowHeight={25}
        componentsProps={{
        }}
      />
    </div>
  )
}


// onRowOver={handleRowOver}
export default ViewListItems
