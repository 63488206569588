import React, { useState, useEffect } from 'react'
import { useHistory  } from 'react-router-dom'
import Logo from 'images/logo-vapalape.svg'
import Typography from '@material-ui/core/Typography'
import useGaTracker from 'services/useGaTracker'
import API from 'api'
import {Helmet} from 'react-helmet'

const Home = () => {
  const history = useHistory()
  const [total, setTotal] = useState({items: 0, shops: 0})
  const [input, setInput] = useState('')

  useGaTracker()

  const resetInput = () => {
    setInput('')
  }

  const search = (e) => {
    e.preventDefault()

    if(input.length < 3) return

    const encoded = encodeURIComponent(input)
    history.push(`/search?terms=${encoded}`)
  }

  useEffect(() => {
    let mounted = true
    const fetchTotals = async() => {
      API.totals()
         .then(results => {
           if(!mounted) return
           setTotal({items: results.data.total, shops: results.data.shops})
         })
    }
    fetchTotals()
    return () => mounted = false
  }, []);

  return (
    <>
      <Helmet>
        <meta name="robots" content="index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1" />
        <link rel="canonical" href="https://vapalape.fr"/>
        <meta property="og:url" content='https://vapalape.fr' />
      </Helmet>

      <nav className="nav">
        <ul className="nav__list">
          <li className="nav__item"><a onClick={(_e) => { history.push('/contact') }} className="nav__link">Contact</a></li>
          <li className="nav__item"><a onClick={(_e) => { history.push('/apropos') }} className="nav__link">A propos</a></li>
          {/* <li className="nav__item nav__item--app"> */}
          {/*   <svg id="Layer_1" data-name="Layer 1" xmlns="http:www.w3.org/2000/svg" viewBox="0 0 100 100"><rect x="37" width="26" height="26"/><rect width="26" height="26"/><rect x="74" width="26" height="26"/><rect x="37" y="37" width="26" height="26"/><rect y="37" width="26" height="26"/><rect x="74" y="37" width="26" height="26"/><rect x="37" y="74" width="26" height="26"/><rect y="74" width="26" height="26"/><rect x="74" y="74" width="26" height="26"/></svg> */}
          {/* </li> */}
          {/* <li className="nav__item nav__item--profile"><a href="https:github.com/jlsystem123" target="_blank"></a></li> */}
        </ul>
      </nav>
      <main className="main">
        <div className="main__hero">
          <img src={Logo} alt="Vapalape Logo"/>
        </div>
        <Typography variant="h1" className="home_h1">
          <b>Comparateur de prix de cigarettes électroniques</b><br />d'eliquides et de consommables.
        </Typography>
        <Typography variant="body1">
          <b>Mise à jour quotidienne</b>
        </Typography>
        <br />
        <Typography variant="subtitle2">
          {new Intl.NumberFormat('fr-FR').format(total.items)} articles, {total.shops} boutiques.
        </Typography>
        <div className="main__input">
          <form className="main__form" onSubmit={search}>
            <div className="form__group">
              <div className="form__logo--search">
                <svg focusable="false" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M15.5 14h-.79l-.28-.27A6.471 6.471 0 0 0 16 9.5 6.5 6.5 0 1 0 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"></path></svg>
              </div>
              <div className="form__input">
                <input
                  value={input}
                  onChange={ (e) => { e.preventDefault(); setInput(e.target.value) } }
                  name="search" id="search" placeholder="Eliquides, materiels, diy, consommables, cbd..." />
              </div>
              <div className="form__logo--mic">
                <svg focusable="false" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" onClick={resetInput}>
                  <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
                </svg>
              </div>
            </div>
          </form>
        </div>
        <div className="main__submit">
          <button type="submit"
                  onClick={search} className="main__submit--search">Comparer les prix</button>
          {/* <button className="main__submit--lucky">Les plus populaires</button> */}
        </div>
      </main>
    </>
  )
}

export default Home
