import React, { useEffect } from 'react'
import Grid from '@material-ui/core/Grid';
import ItemCard from 'components/ItemCard'
import { useAppState } from 'states'
import PaginationButtons from 'components/PaginationButtons'

const ViewCardItems = () => {
  const state = useAppState()

  const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && ( children  ) }
      </div>
    );
  }

  const ItemsList = () => {
    return (
      <Grid container spacing={1} justifyContent="center">
        {state.items.length > 0 && state.items.map((item, idx) => (
          <ItemCard
            key={idx}
            item={item}  />
        ))}
      </Grid>
    )
  }

  // useEffect(() => {
  //   console.log("refresh viewcarditems", state.currentPage, state.currentTab)
  //   switch(state.currentTab) {
  //     case 0:
  //       actions.setItems(() => state.items)
  //       break
  //     case 1:
  //       setItems(() => state.items)
  //       break
  //     case 2:
  //       setItems(() => state.items)
  //       break
  //   }
  // }, [state.currentPage]);
  // }, [data.currentPage, data.results.data, data.currentTab]);

  useEffect(() => {

  });

  return (
    <>
      { state.items.length > 0 && <PaginationButtons /> }
        {[...Array(3).keys()].map((idx) => (
          <TabPanel key={idx} value={state.currentTab} index={idx}>
            <ItemsList />
          </TabPanel>
        ))}
      { state.items.length > 0 && <PaginationButtons /> }
    </>
  )
}

export default ViewCardItems
