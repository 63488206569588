import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Pagination from '@material-ui/lab/Pagination';
import { useAppState, useActions } from 'states'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Grid from '@material-ui/core/Grid'

const useStyles = makeStyles((theme) => ({
  heading: {
    flexGrow: 1,
    justifyContent: "center"
  },
  root: {
    justifyContent: 'center',
    display: 'flex',
    '& > *': {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(3),
    },
  },
}));

const PaginationButtons = () => {
  const state = useAppState()
  const actions = useActions()
  const classes = useStyles()
  const [_mounted, setMounted] = useState(true)
  const [terms, setTerms] = useState(state.terms)

  const handleChangePerPage = async (e) => {
    actions.setPerPage(e.target.value)
    const params = {
      terms: terms,
      fp: state.fingerprint,
      page: 1,
      perPage: e.target.value,
      tab: state.currentTab
    }
    await actions.search(params)
  }

  const handleChangePage = async (_e, value) => {
    const params = {
      terms: terms,
      fp: state.fingerprint,
      page: value,
      tab: state.currentTab
    }
    await actions.search(params)
  }

  useEffect(() => {
    setTerms(() => state.terms)
    return () => setMounted(false)
  }, [state.currentTab, state.terms]);

  return (
    <Grid container className={classes.heading} spacing={2}>
      <Grid item>
        <Pagination
          count={state.totalPages}
          onChange={handleChangePage}
          page={state.currentPage}
          showFirstButton showLastButton siblingCount={1} />
      </Grid>
      <Grid item>
        <FormControl className={classes.formControl}>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={state.perPage}
            onChange={handleChangePerPage}
            label="Age"
          >
            <MenuItem value={20}>20</MenuItem>
            <MenuItem value={40}>40</MenuItem>
            <MenuItem value={60}>60</MenuItem>
            <MenuItem value={100}>100</MenuItem>
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  )
}

export default PaginationButtons
