import axios from 'axios';
import { jsonHeaders } from 'lib'
const { protocol, host } = window.location

const client = axios.create({baseURL: `${protocol}//${host}`});

const API = {
  favs: async (ids) => {
    const resp = await client.post('/api/items/favs', {favs: ids}, {
      headers: jsonHeaders()
    })
    return resp.data
  },
  searchItems: async ({terms, fp, page, similar, cat_id, perPage}) => {
    const encoded = encodeURIComponent(terms)
    const params = new URLSearchParams({
      terms: terms,
      per_page: perPage,
      fp: fp,
      page: page,
      similar: similar,
      cat_id: cat_id || false
    })

    const response = await client.get(`/api/items/search?${params.toString()}`)
    return response
  },
  totals: async () => {
    const response = await client.get('/api/items/total')
    return response
  },
  click: async (params) => {
    const resp = await client.post('/api/items/click', params, {
      headers: jsonHeaders()
    })
    return resp
  },
  cats: async () => {
    const resp = await client.get('/api/cats')
    return resp
  }
}

export default API
