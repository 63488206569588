import { useLocation } from 'react-router-dom'
import moment from 'moment'
import 'moment/locale/fr'

moment.locale('fr')

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
}

const formatPrice = (price) =>{
  return new Intl.NumberFormat('fr-FR',
                               { style: 'currency', currency: 'EUR' }).format(price)
}

const formatDate = (date) => {
  return moment(date).fromNow()
}

const formatNbr = (nbr) => (
  new Intl.NumberFormat('fr-FR').format(nbr)
)

const jsonHeaders = () => {
  const token = document.getElementsByName(
    "csrf-token"
  )[0].content;

 return {
    'Content-Type': 'application/json; charset=utf-8',
    'X-CSRF-Token': token
  }
}

const catId = (tab, facets) => {
  switch(tab) {
    case 0:
      return facets.materiels.id
    case 1:
      return facets.eliquides.id
    default: // 2
      return facets.autres.id
      break
  }
}

const totalPages = (tab, facets, perPage) => {
  let all
  switch(tab) {
    case 0:
      all = facets.materiels.total
      break
    case 1:
      all = facets.eliquides.total
      break
    case 2:
      all = facets.autres.total
      break
  }

  const count = Math.floor(all / perPage)
  const rest = all % perPage
  const total = count + (rest > 0 ? 1 : 0)
  return total
}
export { jsonHeaders, formatDate, formatPrice, formatNbr, useQuery, totalPages, catId }
