import React, { useState, memo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import NoImage from '../images/no_image.svg'
import { formatPrice, formatDate } from 'lib'
import TwitterIcon from '@material-ui/icons/Twitter';
import FacebookIcon from '@material-ui/icons/Facebook';
import IconButton from '@material-ui/core/IconButton';
import ShareIcon from '@material-ui/icons/Share';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Favorite from '@material-ui/icons/Favorite';
import FavoriteBorder from '@material-ui/icons/FavoriteBorder';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import { useAppState, useActions } from 'states'
import API from 'api'

const useStyles = makeStyles((_theme) => ({
  card: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    height: '100%',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flexGrow: '1'
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  media: {
    width: 210,
    height: 210,
  },
  mediaContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  title: {
    fontSize: '1.1rem'
  }

}));
const ItemCard = memo(({item}) => {
  const actions = useActions()
  const state = useAppState()
  const [checked, setChecked] = useState(actions.isFav(item))
  const shareMenuId = 'share-menu';
  const classes = useStyles();


  const [shareAnchorEl, setShareAnchorEl] = useState(null);
  const { desc, img, price, shop, title, updated_at, url } = item.attributes
  const isShareMenuOpen = Boolean(shareAnchorEl)
  const handleShareMenuClose = () => {
    setShareAnchorEl(null)
  }
  // https://github.com/SaraVieira/react-social-sharing/blob/master/src/consts.js
  const handleClickShare = (e, name) => {
    let encoded = encodeURIComponent(title)
    encoded = encodeURIComponent(`https://vapalape.fr/search?terms=${encoded}&similar=true&from_fp=${state.fingerprint}&origin=twitter&query_id=${state.queryID}`)
    // let link = `https://vapalape.fr/search?terms=${encoded}`
    e.preventDefault()

    switch(name) {
      case 'twitter':
        const message = `Comparez les prix de la vape pour "${title}"`
        const uri = `https://twitter.com/intent/tweet/?text=${message}&url=${encoded}`
        window.open(uri, '_blank').focus()
        break
      case 'facebook':
        const link = encodeURIComponent(`https://vapalape.fr/search?terms=${title}&similar=true&query_id=${state.queryID}&from_fp=${state.fingerprint}&origin=fb`)
        window.open(`https://facebook.com/sharer/sharer.php?u=${link}`, '_blank').focus()
        break
    }
    handleShareMenuClose()
  }
  const renderShareMenu = (
    <Menu
      anchorEl={shareAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={shareMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isShareMenuOpen}
      onClose={handleShareMenuClose}
    >
      <MenuItem>
        <IconButton onClick={(e) => { handleClickShare(e, 'twitter') }}>
          <TwitterIcon style={{color: "#1976d2"}}/>
        </IconButton>
      </MenuItem>
      <MenuItem>
        <IconButton onClick={(e) => { handleClickShare(e, 'facebook') }}>
          <FacebookIcon style={{color: "#1976d2"}}/>
        </IconButton>
      </MenuItem>
    </Menu>
  )
  const handleShareMenuOpen = (event) => {
    setShareAnchorEl(event.currentTarget)
  }
  const handleClick = (e) => {
    e.preventDefault()
    const params = {
      query_id: state.queryID,
      shop: item.attributes.shop,
      url: item.attributes.url,
      title: item.attributes.title
    }

    API.click(params)
       .then(_result => null)

    window.open(`${url}?from=vapalape`)
  }
  const handleSearchClick = (e) => {
    e.preventDefault()
    actions.setSimilar(true)
    const win = window.open(`/search?terms=${title}&similar=true`, "_blank");
    win.focus();
  }

  const handleClickFav = (item) => {
    if(checked) {
      actions.delFav(item)
    } else {
      actions.addFav(item)
    }
    setChecked(() => !checked)
  }

  return (
    <Grid item xs={12} sm={4} md={3}>
      <Card className={classes.card} >
        <CardHeader
          subheader={
            <Box display="flex" justifyContent="flex-end"
                 flexDirection="row">
              <Tooltip title="Ajouter à vos favoris" arrow>
                <Checkbox
                  icon={<FavoriteBorder/>}
                  size="small"
                  checkedIcon={<Favorite/>}
                  checked={checked}
                  onChange={(_e) => handleClickFav(item)}
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
              </Tooltip>
              <Tooltip title="Partager vos trouvailles" arrow>
                <IconButton
                  size="small"
                  aria-label="Partage"
                  aria-controls={shareMenuId}
                  aria-haspopup="true"
                  onClick={handleShareMenuOpen}
                  color="inherit"
                >
                  <ShareIcon />
                </IconButton>
              </Tooltip>
            </Box>
          }
          title={title}
          classes={{title: classes.title}}
        />

        <CardActionArea onClick={handleClick}>
          <div className={classes.mediaContainer}>
            <CardMedia
              component="img"
              image={img ? img : NoImage }
              title={title}
              className={classes.media}
            />

          </div>
          <CardContent className={classes.content}  >
            <Typography style={{marginBottom: 12}} variant="body2" gutterBottom color="secondary">
              {shop}
            </Typography>
            <Typography variant="body2" gutterBottom color="textSecondary" component="p">
              {desc}
            </Typography>
          </CardContent>
        </CardActionArea>
        <CardActions className={classes.actions}>
          <Button  size="large" color="secondary" onClick={handleClick}>
            {formatPrice(price)}
          </Button>
          <Tooltip title="Rechercher sur le titre exact" aria-label="Rechercher sur le titre exact">
            <Button variant="outlined" size="small" color="default" onClick={handleSearchClick}>
              <Typography>
                similaires
              </Typography>
            </Button>
          </Tooltip>
          <Button  size="small" color="primary" onClick={handleClick}>
            {formatDate(updated_at)}
          </Button>
        </CardActions>
        {renderShareMenu}
      </Card>
    </Grid>
  );
})

export default ItemCard
