import React from 'react'
import { createOvermind } from "overmind";
import { Provider } from "overmind-react";
import { config } from "states";
import { useLocation } from 'react-router-dom'
import SearchAppBar from 'components/SearchAppBar'
import Footer from "components/Footer"

const overmind = createOvermind(config);

const Layout = ({children}) => {
  const location = useLocation()

  return (
    <Provider value={overmind}>
      <div className="page">
        <div className="page__container" id="back-to-top-anchor">
          {
            location.pathname !== '/' ? <SearchAppBar /> : null
          }
          {children}
        </div>

          <Footer />
      </div>
    </Provider>
  )
}

export default Layout
