import React, { useRef, useState, useEffect } from 'react'
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useSnackbar } from 'notistack'

const AlertDialog = ({terms}) => {
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(false);
  const [email, setEmail] = useState('');
  const [disabled, setDisabled] = useState(true);
  const [helperText, setHelperText] = useState('');

  const { enqueueSnackbar } = useSnackbar()
  const emailRef = useRef()

  const createAlert = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setDisabled(false)
    setError(false)
    setHelperText('')
  };

  const handleSubscribe = () => {
    const token = document.getElementsByName(
      "csrf-token"
    )[0].content;

     const headers = {
      'Content-Type': 'application/json; charset=utf-8',
      'X-CSRF-Token': token
    }

    const opts = {
      method: 'POST',
      headers: headers,
      body: JSON.stringify({ email: emailRef.current.value, terms: terms })
    }

    fetch('/api/alerts/subscribe', opts)
      .then(response => response.json())
      .then(result => {
        if (result.error === undefined) {
          enqueueSnackbar('Souscription validée', { variant: 'success' })
        } else
          enqueueSnackbar(JSON.stringify(result.error), { variant: 'error' })
        handleClose()
      })
  }

  const handleEmail = (e) => {
    const val = emailRef.current.value
    if(val.length < 4) {
      setError(false)
      setHelperText('')
      return
    }

    if(/^[^@]+@[^@]+[.a-zA-z]*.([a-zA-Z]){2,2}$/.test(val)){
      setError(false)
      setDisabled(false)
      setHelperText('')
    } else {
      setError(true)
      setDisabled(true)
      setHelperText('Erreur sur le format')
    }
  }

  useEffect(() => {
  }, [email]);

  return (
    <div>
      <Button onClick={createAlert} size="small" style={{marginBottom: 10}} color="secondary" variant="outlined">
        Créer une alerte
      </Button>

    <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Créer une alerte pour '{terms}'</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Pour recevoir un relevé quotidien des prix pour '{terms}',
          merci de laisser votre adresse courriel ci-dessous.

          Bonne Vape !
        </DialogContentText>
        <TextField
          autoFocus
          inputRef={emailRef}
          error={error}
          helperText={helperText}
          onChange={handleEmail}
          label='Adresse courriel'
          margin="dense"
          id="name"
          type="email"
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Annuler
        </Button>
        <Button onClick={handleSubscribe} color="secondary" disabled={disabled}>
          Souscrire
        </Button>
      </DialogActions>
    </Dialog>
</div>
  )
}

export default AlertDialog
