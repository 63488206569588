import React from 'react'
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="footer">
      <div>
        <ul className="footer__list">
          <li className="footer__item" style={{"paddingLeft": 0}}>
            <Link className="footer__link" to="/contact">Contact</Link>
          </li>
          <li className="footer__item">
            <Link className="footer__link" to="/apropos">A propos</Link>
          </li>
          <li className="footer__item">
            <a target="_blank" href="https://twitter.com/vapalape">Twitter</a>
          </li>
          <li className="footer__item">
            <a target="_blank" href="https://www.facebook.com/Vapalape/">Facebook</a>
          </li>
        </ul>
      </div>
    </footer>
  )
}

export default Footer
