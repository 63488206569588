import { createStateHook, createActionsHook } from 'overmind-react'
import API from 'api'
import { totalPages, catId } from 'lib'

export const config = {
  state: {
    facets: {
      materiels: { id: -1, total: 0 },
      eliquides: { id: -1, total: 0 },
      autres: { id: -1, total: 0 },
    },
    similar: false,
    items: [],
    favs: [],
    cats: {},
    perPage: 20,
    terms: "",
    viewList: JSON.parse(window.localStorage.getItem('viewList')) || false,
    loading: false,
    currentTab: 0,
    currentPage: 1,
    queryID: null,
    fingerprint: null,
    totalPages: 0,
    zeroDialogDone: JSON.parse(window.localStorage.getItem('zeroDialogDone')) || false
  },
  actions: {
    onInitializeOvermind: async ({state, actions, effects}, overmind) => {
      state.favs = effects.getFavs()
      state.facets = await effects.getCatsCounts()
    },
    setPerPage: ({state}, perPage) => {
      state.perPage = perPage
    },
    setSimilar: ({state}, similar) => {
      state.similar = similar
    },
    favsNbr: ({state}) => {
      return Object.keys(state.favs).length
    },
    isFavEmpty({state}) {
      return this.favsNbr() == 0 ? true : false
    },
    fetchFavs: async ({state, effects}) => {
      const favs = await effects.fetchFavs(state.favs)
      return favs
    },
    addFav({state}, item) {
      state.favs = [...state.favs, item.id]
      localStorage.setItem('favs', JSON.stringify(state.favs))
    },
    delFav({state}, item) {
      state.favs = state.favs.filter(id => id !== item.id)
      localStorage.setItem('favs', JSON.stringify(state.favs))
    },
    isFav({state}, item) {
      const r = _.indexOf(state.favs, item.id) === -1 ? false : true
      return r
    },
    search: async({state, effects}, params) => {
      params = {...params,
                cat_id: catId(params.tab || 0, state.facets),
                perPage: params.perPage || state.perPage,
                similar: params.similar || state.similar
               }
      state.loading = !state.loading
      const { data,
              facets,
              query_id } = await effects.searchItems(params)
      state.items = data
      state.facets = facets
      state.queryID = query_id
      state.currentPage = params.page || 1
      state.terms = params.terms
      state.currentTab = params.tab || 0
      state.totalPages = totalPages(state.currentTab, state.facets, state.perPage)
      state.loading = !state.loading
    },
    setFingerprint({state}, fingerprint) {
      state.fingerprint = fingerprint
    },
    toggleListView({state}) {
      state.viewList = !state.viewList
    },
    toggleZeroDialog({state}) {
      state.zeroDialogDone = !state.zeroDialogDone
    },
  },
  effects: {
    searchItems: async (params) => {
      const { data } = await API.searchItems(params)
      return data
    },
    fetchFavs: async (ids) => {
      const { data } = await API.favs(ids)
      return data
    },
    getCatsCounts: async () => {
      const { data } = await API.cats()
      return data
    },
    getFavs: () => {
      return JSON.parse(localStorage.getItem('favs')) || []
    }
  }
};

export const useAppState = createStateHook();
export const useActions = createActionsHook();
