import React from 'react';
import Home from 'pages/Home';
import Search from 'pages/Search';
import Contact from 'pages/Contact'
import About from 'pages/About'
import Favoris from 'pages/Favoris'
import { BrowserRouter as Router, Route, Switch} from "react-router-dom";
import Layout from 'components/Layout'

function App() {
  return (
    <Router forceRefresh={false}>
      <Layout>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/search" component={Search} />
          <Route exact path="/contact" component={Contact} />
          <Route exact path="/apropos" component={About} />
          <Route exact path="/favoris" component={Favoris} />
          <Route exact path="/unsubscribe" component={Contact} />
        </Switch>
      </Layout>
    </Router>
  );
}

export default App;
