import React, { useEffect, useState } from 'react'
import Container from '@material-ui/core/Container';
import { withStyles } from '@material-ui/core/styles';
import useGaTracker from 'services/useGaTracker'
import { Button, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import ItemCard from 'components/ItemCard'
import { useHistory  } from 'react-router-dom'

import { useActions, useAppState } from 'states'

const styles = theme => ({
  cardGrid: {
    flex: 2,
    justifyContent: 'center',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(8),
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1',
    padding: theme.spacing(2),
    marginBottom: theme.spacing(5)
  },
  list: {
    margin: theme.spacing(3)
  },
  card: {
    margin: theme.spacing(3)
    // maxWidth: 400
  },
  media: {
    width: "100%",
    marginTop: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(1)
  }
});

const Favoris = withStyles(styles)(({classes}) => {
  const state = useAppState()
  const actions = useActions()
  const [favs, setFavs] = useState([])

  const history = useHistory()
  useGaTracker()

  useEffect(() => {
    let mounted = true

    const fetchData = async () => {
      if(!mounted) return
      const data = await actions.fetchFavs()
      setFavs(() => data)
    }
    fetchData()

    return () => {
      mounted = false
    }
  }, []);

  return (
    <Container className={classes.root}>
      <Typography variant="h5">
        Vos favoris
      </Typography>
      <Button onClick={(_e) => history.push(`/search?terms=${state.terms}`) }>
        <Typography>
          retour a la recherche
        </Typography>
      </Button>
    <Grid container spacing={1} justifyContent="center">
      {
        favs.map((item, idx) => {
          return <ItemCard key={idx} item={item}/>
        })
      }
    </Grid>
    </Container>
  )
})

export default Favoris
