import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Container from '@material-ui/core/Container';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Typography from '@material-ui/core/Typography';
import SearchBarImg from 'images/search_bar.png';
import SearchResultImg from 'images/search_result.png';
import TableSimilar from 'images/table-similaire.png';
import ResultsHeaderImg from 'images/results_header.png';
import useGaTracker from 'services/useGaTracker'
import {Helmet} from 'react-helmet'

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1',
    padding: theme.spacing(4),
  },

  list: {
    margin: theme.spacing(3)
  },
  card: {
    margin: theme.spacing(3)
    // maxWidth: 400
  },
  media: {
    width: "100%",
    marginTop: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(1)
  }
});
const About = withStyles(styles)(({ classes }) => {

  useGaTracker()

  return (
    <>
      <Helmet>
        <meta name="robots" content="index, nofollow, max-image-preview:large, max-snippet:-1, max-video-preview:-1" />
      </Helmet>
      <Container className={classes.root} >
        <Card className={classes.card}>
          <CardHeader title="Le constat"/>
          <CardContent>
            <Typography variant="body1">
              Malgré une offre pléthorique via les boutiques en ligne, il n'existe
              <b> pas de solution simple</b> pour <b>comparer les prix des produits de la vape</b>.
            </Typography>
          </CardContent>
        </Card>
        <Card className={classes.card}>
          <CardHeader title="La solution VAPALAPE.FR"/>
          <CardContent>
            <Typography variant="body1">
              Vapalape relève les prix <b>une fois par jour</b> sur l'ensemble des
              boutiques spécialisées francophones.
            </Typography>
          </CardContent>
        </Card>
        <Card className={classes.card}>
          <CardHeader title="La recherche"/>
          <CardContent>
            <Typography variant="body1">
              Entrer les mots clefs dans la zone de texte de la bar de recherche.
            </Typography>
            <Typography variant="body1">
              <b>Les produits dont le nom est composé des mots clefs</b> forment les résultats.
            </Typography>
            <img src={SearchBarImg} className={classes.media}/>
          </CardContent>
        </Card>
        <Card className={classes.card}>
          <CardHeader title="Les résultats de la recherche"/>
          <CardContent>
            <Typography>
              <b>Trois onglets</b> offrent un classement rudimentaire : <b>MATERIELS</b>, <b>ELIQUIDES</b> et <b>AUTRES</b>
            </Typography>
            <Typography>
              Pour recevoir un courriel quotidien pour cette recherche, un bouton <b>CRÉER UNE ALERTE</b> est disponible.
            </Typography>
            <Typography>
              Un bouton <b>`SIMILAIRES`</b> pour lancer une recherche présice sur le titre du produit.
            </Typography>
            <Typography>
              Un click sur le <b>prix</b>, <b>la photo</b> ou <b>la date de mise à jour</b> ouvre une nouvelle fenêtre vers la fiche produit de la boutique.
            </Typography>
            <Typography>
              Un bouton pour marquer une fiche dans ses <b>favoris</b>.
            </Typography>
            <Typography>
              Un autre bouton pour partager ses trouvailles sur <b>Twitter</b> et/ou <b>Facebook</b>
            </Typography>
            <img src={SearchResultImg} className={classes.media}/>
          </CardContent>
        </Card>
    {/*     <Card className={classes.card}> */}
    {/*       <CardHeader title="Les résultats de la recherche - Mode TABLE"/> */}
    {/*       <CardContent> */}
    {/*         <Typography variant="body1"> */}
    {/*           <b>En haut à droite</b> de la page de resultats, il est possible de visualiser les produits */}
    {/*           sous forme de table. */}
    {/*         </Typography> */}
    {/*         <Typography> */}
    {/*           Les colonnes sont triables. */}
    {/*         </Typography> */}
    {/*         <img src={ResultsHeaderImg} className={classes.media}/> */}
    {/*         <ul className={classes.list}> */}
    {/*           <li> */}
    {/*             <b>COLONNES</b> : Les colonnes à afficher; */}
    {/*           </li> */}
    {/*           <li> */}
    {/*             <b>FILTRES</b> : Affiner les résultats; */}
    {/*           </li> */}
    {/*           <li> */}
    {/*             <b>DENSITÉ (style de la table)</b> : Compact, Standard, Confortable */}
    {/*           </li> */}
    {/*           <li> */}
    {/*             <b>EXPORTER</b> : Télécharger au format CSV. */}
    {/*           </li> */}
    {/*         </ul> */}

    {/*         <Typography> */}
    {/*           Un bouton <b>`Visiter`</b> pour se rendre sur le site de la boutique. */}
    {/*         </Typography> */}
    {/*         <Typography> */}
    {/*           Un bouton <b>`Similaires`</b> pour lancer une recherche présice sur le titre du produit. */}
    {/*         </Typography> */}
    {/* <div style={{flexWrap: 'nowrap'}}> */}
    {/*           <img src={TableSimilar} width="1483" height="220" className={classes.media}/> */}
    {/*         </div> */}
    {/*       </CardContent> */}
    {/*     </Card> */}
      </Container>
    </>
  )});

export default About;
