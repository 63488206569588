import React, { useEffect, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { formatNbr } from 'lib'
import { useAppState, useActions } from 'states'
import { useQuery } from 'lib'

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    width: '100%',
  },
}));

export default function FullWidthTabs() {
  const state = useAppState()
  const similar = useQuery().get('similar')
  const actions = useActions()
  const [_mounted, setMounted] = useState(true)

  const classes = useStyles();
  const theme = useTheme();

  const { materiels, eliquides, autres } = state.facets

  const handleChange = async (_event, value) => {
    if(value == state.currentTab) return
    const params = {
      terms: state.terms,
      fp: state.fingerprint,
      similar: similar,
      tab: value
    }

    await actions.search(params)
  };

  useEffect(() => {
    return () => setMounted(false)
  });

  return (
    <AppBar position="fixed" color="default" component="div" style={{marginTop: "52px"}}>
        <Tabs
          value={state.currentTab}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          aria-label="Categories: Tous, Materiels, Eliquides ou Autres"
        >
          <Tab
            label={`Materiels (${formatNbr(materiels.total)})`} {...a11yProps(1)}
            disabled={materiels.total == 0 ? true : false}
          />
          <Tab label={`Eliquides (${formatNbr(eliquides.total)})`} {...a11yProps(2)}
            disabled={eliquides.total == 0 ? true : false}
          />
          <Tab label={`Autres (${formatNbr(autres.total)})`} {...a11yProps(3)}
            disabled={autres.total == 0 ? true : false}
          />
        </Tabs>
      </AppBar>
  );
}
