import React, { useCallback, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import ViewListItems from 'components/ViewListItems'
import ViewCardItems from 'components/ViewCardItems'
import ZeroDialog from 'components/ZeroDialog'
import AlertDialog from 'components/AlertDialog'
import Typography from '@material-ui/core/Typography'

import useScrollTrigger from '@material-ui/core/useScrollTrigger'
import Fab from '@material-ui/core/Fab'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import Zoom from '@material-ui/core/Zoom'
import {Helmet} from 'react-helmet'
import useGaTracker from 'services/useGaTracker'
import CircularProgress from '@material-ui/core/CircularProgress';
import { useQuery, formatNbr } from 'lib'
import Backdrop from '@material-ui/core/Backdrop'
import Grid from '@material-ui/core/Grid'
import { useAppState, useActions } from 'states'

const useStyles = makeStyles((theme) => ({
  scroll: {
    position: 'fixed',
    bottom: theme.spacing(6),
    right: theme.spacing(1),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  heading: {
    flexGrow: 1,
    justifyContent: "space-between"
  },
  cardGrid: {
    flex: 2,
    minWidth: '80%',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
}));

function ScrollTop(props) {
  const { children, window } = props;
  const classes = useStyles();
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    // target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector('#back-to-top-anchor');

    if (anchor) {
      anchor.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
    }
  };

  return (
    <Zoom in={trigger}>
      <div onClick={handleClick} role="presentation" className={classes.scroll}>
        {children}
      </div>
    </Zoom>
  );
}

function Search(props) {
  const state = useAppState()

  const termsParam = useQuery().get('terms')
  const classes = useStyles()

  useGaTracker()

  const countLabel = () => (
    `${formatNbr(getTabTotal())} ${getTabLabel()}`
  )

  const getTabLabel = () => {
    switch(state.currentTab) {
      case 0:
        return "matériels"
      case 1:
        return "E liquides"
      case 2:
        return "autres"
    }
  }

  const getTabTotal = () => {
    switch(state.currentTab) {
      case 0:
        return state.facets.materiels.total
      case 1:
        return state.facets.eliquides.total
      case 2:
        return state.facets.autres.total
    }
  }

  useEffect(() => {
    // const anchor = document.querySelector('#back-to-top-anchor');

    // if (anchor) {
    //   anchor.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
    // }
  }, [state.currentTab, state.terms]);

  return (
    <>
      <Helmet>
        <meta name="robots" content="nofollow" />
        <meta charSet="utf-8" />
        <title>
          Vapalape.fr - Résultats pour {state.terms || termsParam}
        </title>
        <link rel="canonical"
              href={'https://vapalape.fr/search?terms=' + encodeURIComponent(termsParam) }/>
        <meta property="og:url"
              content={'https://vapalape.fr/search?terms=' + encodeURIComponent(termsParam) }/>
      </Helmet>
      <Container className={classes.cardGrid} maxWidth="md" >
        <Grid container className={classes.heading} spacing={2}>
          <Grid item>
            <Typography  style={{marginBottom: 10}} variant="h1" className="home_h1">
              <b>{countLabel()}</b> pour <b><i>'{ state.terms || termsParam }'</i></b>
            </Typography>
          </Grid>
          { !state.zeroDialogDone && state.facets.materiels == 0 && <ZeroDialog /> }

          <Grid item>
            <AlertDialog terms={state.terms}/>
          </Grid>
        </Grid>
        {
          (!state.loading && <ViewCardItems />) ||
           /* ((state.viewList && <ViewListItems />) || <ViewCardItems />)) || */
          <Backdrop
            className={classes.backdrop}
            open={state.loading}>
            <CircularProgress color="inherit" />
          </Backdrop>
        }

      </Container>

      <ScrollTop {...props}>
        <Fab color="secondary" size="small" aria-label="Revenir au debut de la page">
          <KeyboardArrowUpIcon />
        </Fab>
      </ScrollTop>
    </>
  )
}

export default Search
