import React from 'react';
import ReactDOM from 'react-dom';
import App from 'components/App';
import CssBaseline from '@material-ui/core/CssBaseline';
import { SnackbarProvider } from 'notistack'

document.addEventListener('DOMContentLoaded', () => {
  ReactDOM.render(
    <React.StrictMode>
        <CssBaseline />
        <SnackbarProvider maxSnack={3}   anchorOrigin={{vertical: 'top', horizontal: 'center',}}>
            <App />
        </SnackbarProvider>
    </React.StrictMode>,
    document.body.appendChild(document.createElement('div'))
  )
})

// import GA4React from 'ga-4-react'

// const ga4react = new GA4React("G-X57NWPDRMH")


// (async _ => {

//   await ga4react.initialize()
//                 .then(res => console.log("Analytics Success."))
//                 .catch(err => console.log("Analytics Failure."))
//                 .finally(() => {
//                   <React.StrictMode>
//                     <StateProvider initialState={initialState} reducer={reducer}>
//                       <CssBaseline />
//                       <SnackbarProvider maxSnack={3}   anchorOrigin={{vertical: 'top', horizontal: 'center',}}>
//                         <App />
//                       </SnackbarProvider>
//                     </StateProvider>
//                   </React.StrictMode>,
//                   document.body.appendChild(document.createElement('div'))
//                 });
// })();
