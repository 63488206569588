import React, { useState, useEffect } from 'react'

import { useHistory } from 'react-router-dom'
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import CircularProgress from '@material-ui/core/CircularProgress'
import { useSnackbar } from 'notistack'
import useGaTracker from 'services/useGaTracker'
import { jsonHeaders } from 'lib'

const useStyles = makeStyles((theme) => ({
  cardGrid: {
    flex: 2,
    justifyContent: 'center',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(8),
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const MyTextField = ({ isInvalid, ...props }) => {
  const invalid = isInvalid(props.value);
  return (
    <TextField
      {...props}
      error={Boolean(invalid)}
      helperText={invalid || props.helperText}
    />
  );
};

const Contact = () => {
  const history = useHistory()
  const { enqueueSnackbar } = useSnackbar()
  const classes = useStyles();
  const [inputs, setInputs] = useState([
    {
      id: 'email',
      variant: 'outlined',
      required: true,
      fullWidth: true,
      autoFocus: true,
      name: 'Couriel',
      label: 'Couriel',
      value: '',
      helperText: '',
      value: '',
      autoComplete: 'email',
      isInvalid: value =>
      value === '' || /\S+@\S+\.\S+/.test(value)
        ? false
        : 'Erreur sur le format'
    },
    {
      id: 'msg',
      label: 'Message',
      variant: 'outlined',
      required: true,
      fullWidth: true,
      multiline: true,
      value: '',
      minRows: 10,
      helperText: 'Max 300 caracteres',
      autoComplete: 'none',
      isInvalid: value =>
      value === '' || value.length <= 300 ? false : 'Message un peu trop long'
    }
  ]);


  const [sending, setSending] = useState(false)
  const [alertId, setAlertId] = useState('');

  useGaTracker()

  function send(event) {
    event.preventDefault();
    let body
    let url

    const headers = jsonHeaders()

    setSending(true)

    const emailInput = inputs.findIndex(input => input.id === 'email');
    const msgInput = inputs.findIndex(input => input.id === 'msg');

    const msg = inputs[msgInput].value
    const email = inputs[emailInput].value

    if(isUnsubscribe()) {
      url = `/api/alerts/${alertId}/unsubscribe`
      body = { email: email, msg: msg }
    } else {
      url = '/api/contacts/msg'
      body = { from: email, msg: msg }
    }

    const opts = {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(body)
    }

    fetch(url, opts)
      .then(response => response.json())
      .then(result => {
        setSending(false)
        if (result.error === undefined) {
          if(isUnsubscribe()) {
            enqueueSnackbar('Alerte désactivée', { variant: 'success', autoHideDuration: 2000 })
          } else {
            enqueueSnackbar('Message envoyé', { variant: 'success', autoHideDuration: 2000 })
          }
          inputs[msgInput].value = ''
          inputs[emailInput].value = ''
          history.push('/')
        } else {
          enqueueSnackbar(JSON.stringify(result.error), { variant: 'error' })
        }
      })
  }

  const isUnsubscribe = () => {
    return location.pathname === '/unsubscribe'
  }
  const onChange = ({ target: { id, value } }) => {
    setInput(value, id)
  };


  const setInput = (content, id) => {
       const newInputs = [...inputs];
      const index = inputs.findIndex(input => input.id === id);

      newInputs[index] = {
        ...inputs[index],
        value: content
      };
      setInputs(newInputs);
  }

  useEffect(() => {
    if(history.location.state !== undefined) {
      setInput(history.location.state.msg, 'msg')
    }

    if(isUnsubscribe()) {
      const params = new URLSearchParams(location.search);
      const email = params.get('email')
      const alert_id = params.get('alert_id')
      setAlertId(alert_id)
      setInput(email, 'email')
      setInput('Merci de désactiver mon alerte pour les raisons suivantes :', msg)
    }
    return () => {
      setAlertId('')
      setInput('', 'msg')
      setInput('', 'email')
    }
  }, [location.pathname]);

  return (
    <Container className={classes.cardGrid} component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>

        {sending && <CircularProgress />}

        <form onSubmit={send} id="form-msg" className={classes.form} >
          <Grid container spacing={2}>
            {inputs.map(input => (
              <Grid key={input.id} item xs={12}>
                <MyTextField {...input} onChange={onChange} />
              </Grid>
            ))}
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            { isUnsubscribe() ? "Désactiver" : "Envoyer" }
          </Button>
        </form>
      </div>
    </Container>
  );
}


export default Contact
