import React, { useState, useEffect } from 'react';
import MoreIcon from '@material-ui/icons/MoreVert';
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';
import Badge from '@material-ui/core/Badge';
import { getCurrentBrowserFingerPrint } from "@rajesh896/broprint.js"
import IconButton from '@material-ui/core/IconButton';
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';
import MailIcon from '@material-ui/icons/Mail';
import InfoIcon from '@material-ui/icons/Info';
import Favorite from '@material-ui/icons/Favorite';
import CloseIcon from '@material-ui/icons/Close';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import AppBar from '@material-ui/core/AppBar';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom'
import Logo from 'images/logo-vapalape.svg'
import LogoMobile from 'images/logo.svg'
import FullWidthTabs from 'components/Tabs'
import { Typography } from '@material-ui/core';
import { useAppState, useActions } from 'states'
import { useQuery } from 'lib'

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2),
    marginTop: '16px',
  },
  grow: {
    flexGrow: 1,
    // height: '15px',
    marginBottom: 10,
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  cardGrid: {
    flex: 2,
    justifyContent: 'center',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(8),
  },
  homeLogo: {
    height: '44px',
    width: '120px',
    marginLeft: 20,
    [theme.breakpoints.down('md')]: {
      marginLeft: 2,
      // height: '44px',
      width: '90px',
    },
  },
  logoMobile: {
    height: '44px',
    width: '44px',
    display: 'none',
  },
  inputForm: {
    // display: 'flex'
    marginLeft: 20,
    // marginRight: 50,
    flexGrow: 2,
    [theme.breakpoints.down('md')]: {
      marginLeft: 5,
    }
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
}));
const mobileMenuId = 'primary-search-account-menu-mobile';

const SearchAppBar = () => {
  const state = useAppState()
  const actions = useActions()
  const [inputValue, setInputValue] = useState(state.terms)
  const history = useHistory()
  const [_anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const classes = useStyles()

  const search = async (e) => {
    e.preventDefault()


    if(inputValue.length < 3) return

    actions.search({
      terms: inputValue,
      fp: state.fingerprint,
      page: 1,
      similar: false,
      tab: 0
    })

    if(location.pathname.match(/^\/search/)) {
      window.history.pushState({}, null, `/search?terms=${inputValue}`);
    } else {
      history.push(`/search?terms=${inputValue}`)
    }
  }

  const resetInput = () => {
    setInputValue('')
  }

  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      {

        state.favs.length > 0  &&
          <MenuItem onClick={(_e) => { history.push('/favoris'); handleMenuClose() } }>

            <IconButton color="inherit">
              <Badge badgeContent={state.favs.length} color="secondary">
                <Favorite />
              </Badge>
            </IconButton>
            <p>Favoris</p>
          </MenuItem>
      }
      {/* { */}
      {/*   location.pathname.match(/\/search.*\/) && ( */}

      {/*     <MenuItem onClick={toggleView}> */}
      {/*       <IconButton aria-label="vue table" color="inherit"> */}
      {/*         { (state.viewList && <PhotoLibraryIcon />) || <FormatListNumberedIcon /> } */}
      {/*       </IconButton> */}
      {/*       { (state.viewList && "Vue vignettes") || "Vue table" } */}
      {/*     </MenuItem> */}
      {/*   ) */}
      {/* } */}

      <MenuItem onClick={(e) => { history.push('/contact'); handleMenuClose() } }>
        <IconButton aria-label="" color="inherit">
          <MailIcon />
        </IconButton>
        <p>Contact</p>
      </MenuItem>
      <MenuItem onClick={(e) => { history.push('/apropos'); handleMenuClose() } }>
        <IconButton
          aria-label=""
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <InfoIcon />
        </IconButton>
        <p>A propos</p>
      </MenuItem>
      <MenuItem onClick={(_e) => setMobileMoreAnchorEl(null) }>
        <IconButton color="secondary">
          <CloseIcon />
        </IconButton>
        Fermer
      </MenuItem>
    </Menu>
  );
  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };
  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const toggleView = (e) => {
    e.preventDefault()
    actions.toggleListView()
    window.localStorage.setItem('viewList', JSON.stringify(!state.viewList))
    handleMenuClose()
  }

  // useEffect(() => {
  //   actions.setTerms(termsParam || '')
  // }, [state.terms]);

  const searchParams = useQuery().get('terms')
  const similar = useQuery().get('similar') === 'true' ? true : false

  useEffect(() => {
    actions.setSimilar(similar)
    const fetchData = async () => {
      const fp = await getCurrentBrowserFingerPrint().then((val) => {
        return val
      })

      actions.setFingerprint(fp)

      await actions.search({terms: searchParams,
                            fp: fp,
                            page: 1,
                            tab: 0,
                            similar: similar})

      setInputValue(searchParams)
    }

    fetchData()
  }, []);

  return (
    <>
      <AppBar color="default" position="sticky">
        <div className="main__input" style={{marginTop: 5, display: "flex"}}>
          <a href="/">
            <img title="Vapalape.fr - Comaprateur des prix de la Vape" id='logo_desktop'
                 src={Logo} className={classes.homeLogo} alt="Vapalape Logo"/>
            <img title="Vapalape.fr - Comaprateur des prix de la Vape" id='logo_mobile'
                 src={LogoMobile} className={classes.logoMobile} alt="Vapalape Logo"/>

          </a>
          <form className={classes.inputForm} onSubmit={search}>
            <div className="form__group">
              <div className="form__logo--search">
                <svg focusable="false" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M15.5 14h-.79l-.28-.27A6.471 6.471 0 0 0 16 9.5 6.5 6.5 0 1 0 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"></path></svg>
              </div>
              <div className="form__input">
                <input
                  value={inputValue}
                  onChange={ (e) => {
                    e.preventDefault()
                    setInputValue(() => e.target.value)
                   }
                  }
                  name="search" id="search" placeholder="Eliquides, materiels, diy, consommables, cbd..." />
              </div>

              <div className="form__logo--mic">
                <svg focusable="false" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" onClick={resetInput}>
                  <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
                </svg>
              </div>
            </div>
          </form>
          <div className={classes.grow} />
          <div className={classes.sectionDesktop}>
            {
              state.favs.length > 0  &&
                <MenuItem onClick={(_e) => { history.push('/favoris') } }>
                  <Badge badgeContent={state.favs.length} color="secondary">
                    <Typography>Favoris</Typography>
                  </Badge>
                </MenuItem>

            }
            {
            /*   location.pathname.match(/\/search.*\/) && ( */
            /*   <MenuItem onClick={toggleView}> */
            /*     { (state.viewList && "Vue vignettes") || "Vue table" } */
            /*   </MenuItem> */
            /* ) */
            }
            <MenuItem
              onClick={(_e) => { history.push('/contact') } }>
              <Typography>
                Contact
              </Typography>
            </MenuItem>
            <MenuItem
              onClick={(_e) => { history.push('/apropos') } }>
              <Typography>
                A propos
              </Typography>
            </MenuItem>
          </div>
          <div className={classes.sectionMobile}>
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </div>
        </div>
        { !state.viewList && location.pathname.match(/^\/search/) &&  <FullWidthTabs /> }
      </AppBar>
      {renderMobileMenu}
    </>
  )
}

export default SearchAppBar
